import {NextSeo} from 'next-seo';
import {useRouter} from 'next/router';

const Layout = ({children, meta}) => {
	const router = useRouter();
	const DEFAULT_SEO = {
		title: meta.title || 'Fishing in Godzone | Online Shopping Made Better',
		description:
			meta.description ||
			'A socially conscious online shopping destination where Shoppers, Good Causes and Retailers all do better! All of our products are at our Retailers’ best prices and we make a donation to a Good Cause of your choice every time you shop with us (out of our pocket, not yours).',
		keywords: 'marketplace',
		openGraph: {
			type: 'website',
			locale: 'en_IE',
			url: `${process.env.SITE_URL}${router.asPath}`,
			title: meta.title || 'Fishing in Godzone | Online Shopping Made Better',
			description:
				meta.description ||
				'A socially conscious online shopping destination where Shoppers, Good Causes and Retailers all do better! All of our products are at our Retailers’ best prices and we make a donation to a Good Cause of your choice every time you shop with us (out of our pocket, not yours).',
			// eslint-disable-next-line babel/camelcase
			site_name: 'Fishing in Godzone',
			images: [
				{
					url: `${process.env.SITE_URL}/img/main-logo.png`,
					width: 164,
					height: 46,
					alt: 'Fishing-in-Godzone',
				},
			],
		},
	};

	return (
		<>
			<NextSeo {...DEFAULT_SEO} />
			<>{children}</>
		</>
	);
};

export default Layout;
