import React, {useState, useContext, useEffect, useCallback} from 'react';
import {useRouter} from 'next/router';
import Cookies from 'js-cookie';

import {api} from '@utils/api';
import {convertCurrencyAdjust} from '@utils/functions';
import {getCurrency} from '@utils/currencyFlags';
import {getCountryList} from '@utils/country';

const redirectKey = 'sign_in_redirect';

const AppContext = React.createContext();

const setRedirect = redirect => {
	window.sessionStorage.setItem(redirectKey, redirect);
};

export const AppProvider = ({children}) => {
	const router = useRouter();

	const [idb, setIdb] = useState({
		user: null,
		origin: null,
		isoCode: Cookies.get('isoCode') || 'AU',
		currencyData: [],
		currency: Cookies.get('cur') || 'aud',
		guestUser: null,
		countryList: [],
		showRegisterPop: false,
		logoutUser: false,
	});

	const [modal, setModal] = useState(null);

	const removeGuestuser = useCallback(async () => {
		await api(
			{
				url: '/auth/remove-guest-session',
				method: 'get',
			},
			idb.session,
		);
	}, [idb.session]);

	const showModel = key => {
		setModal(key);
	};

	const fetchSession = useCallback(async (isReload = false, isoCode = null) => {
		let currencyData = [];
		let countryList = [];
		let token = Cookies.get('token');
		const [result, currency, countryData] = await Promise.all([
			api(
				{
					url: '/auth/session?isoCode=' + (isoCode || Cookies.get('isoCode')),
					method: 'GET',
				},
				token,
			),
			api({
				method: 'GET',
				url: `/currency`,
			}),
			token ? getCountryList(token) : [],
		]);

		if (currency && currency.resData) {
			currencyData = currency.resData.map(mp => ({
				...mp,
				src: `/assets/flags/${mp.currency.toLowerCase()}.png`,
			}));
		}

		if (countryData && countryData.status === 200) {
			countryList = countryData.countries;
		}

		Cookies.set('token', result._id);
		Cookies.set('isoCode', result.isoCode);
		const uData = sessionStorage.getItem('guestUser');
		let guestData = null;
		if (result.guestUser && Object.keys(result.guestUser).length > 0) {
			if (uData) guestData = JSON.parse(uData);
			else removeGuestuser();
		}
		setIdb(val => ({
			...val,
			user: result.user ? {...result.user, charity: result.charity} : null,
			origin: result.origin,
			isoCode: result.isoCode || Cookies.get('isoCode') || 'AU',
			session: result._id,
			currency: 'aud',
			guestUser: guestData,
			currencyData,
			countryList,
			showRegisterPop: result.user ? false : true,
		}));
		if (!token) {
			let country = await getCountryList(result._id);
			if (country && country.status === 200) {
				countryList = country.countries;
			}
			setIdb(val => ({
				...val,
				countryList,
			}));
		}
		if (isReload) {
			window.location.reload();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const logout = async () => {
		await api(
			{
				url: '/auth/logout',
			},
			idb.session,
		);
		setIdb(val => ({...val, user: null, logoutUser: true}));
		router.push('/');
	};

	const setUserLocation = async loc => {
		const result = await api(
			{
				url: '/auth/user-location',
				method: 'POST',
				data: {origin: loc, userId: idb?.user?._id},
			},
			idb.session,
		);
		setIdb(val => ({...val, origin: result.origin}));
	};

	useEffect(() => {
		fetchSession();
	}, [fetchSession]);

	const convertCurrency = useCallback(
		(price, code = true, symbol = true, name = false, convertPrice = true) => {
			if (idb.currencyData?.length === 0) return price;
			let currentCurrency = idb.currencyData.find(
				cur => cur.currency.toLowerCase() === idb.currency,
			);
			let defaultAudConvertedRate = idb.currencyData.find(
				cur => cur.currency.toLowerCase() === 'aud',
			);
			let currencySymbol = getCurrency(idb.currency);
			if (currentCurrency && defaultAudConvertedRate) {
				let convertedPrice = convertPrice
					? convertCurrencyAdjust(
							price,
							currentCurrency,
							defaultAudConvertedRate,
					  )
					: Number(price);
				return (
					(code ? ' ' + currencySymbol?.code + ' ' : '') +
					(symbol ? currencySymbol?.symbol_native + '' : '') +
					(price > 0 ? convertedPrice.toFixed(2) : '') +
					(name ? ' ' + currencySymbol?.name + ' ' : '')
				);
			} else {
				return price;
			}
		},
		[idb.currency, idb.currencyData],
	);

	useEffect(() => {
		if (router.query.grc && idb.session && !idb.user) {
			showModel('register');
		}
	}, [router.query, idb]);

	useEffect(() => {
		if (idb.guestUser && idb.guestUser.email)
			sessionStorage.setItem('guestUser', JSON.stringify(idb.guestUser));
	}, [idb.guestUser]);

	/**
	 * Show Currency Options based on Pathname
	 */
	const showConvertCurrency = useCallback(() => {
		// console.log(router.route.split('/'));
		switch (router.route.split('/')[1]) {
			//Home Page
			case '':
			case 'product':
			case 'stores':
			case 'brand':
			case 'wishlist':
			case 'drop-auctions':
			case 'products': {
				return true;
			}
			default:
				return false;
		}
	}, [router.route]);
	return (
		<AppContext.Provider
			value={{
				logout,
				setUserLocation,
				idb,
				setIdb,
				setRedirect,
				showModel,
				modal,
				convertCurrency,
				showConvertCurrency,
				fetchSession,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export function useApp() {
	const context = useContext(AppContext);
	if (context === undefined) {
		throw new Error('useApp must be used within an AppProvider');
	}
	return context;
}
